<template>
  <main>
    <div class="flex items-center">
      <svg
        class="w-3 h-3"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 15.5L0.5 8.5L7.5 1.5"
          stroke="#333333"
          stroke-width="1.16667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="text-xs ml-3 leading-6">Make Transfer</div>
    </div>
    <div class="mt-5 mb-2 text-lg">All Beneficiaries</div>

    <div class="mt-10 flex justify-end items-end">
      <trac-button @click.native="addBeneficiaryModal = true">
        <div class="uppercase px-5 flex items-center">
          <svg
            class="w-5 h-5 mr-3"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="9.33333"
              cy="9.33333"
              r="8.33333"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.33341 6V12.6667"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 9.33317H12.6667"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          ADD new beneficiary
        </div>
      </trac-button>
    </div>
    <div class="mt-10">
      <main id="table">
        <div class="table-shadow p-6 rounded-md">
          <div class="flex w-full items-center">
            <div class="relative max-w-sm rounded-md w-full flex items-center">
              <input
                type="text"
                placeholder="Search customer name or amount paid"
                class="input-shadow py-2 w-full pl-5 text-xs focus:outline-none leading-7 tracking-wide text-primaryGray"
              />
              <svg
                class="w-4 h-4 absolute right-0 mr-6"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="6.56705"
                  cy="6.61686"
                  r="5.39909"
                  stroke="#253B95"
                  stroke-width="1.06786"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.3156 13.3659L10.3799 10.4302"
                  stroke="#253B95"
                  stroke-width="1.06786"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="ml-8 relative w-full">
              <button
                v-click-outside="close"
                @click="active = !active"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
                type="button"
                class="border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-4 rounded inline-flex w-32 justify-between capitalize items-center"
              >
                Add filter
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </button>
              <div
                v-show="active"
                @click.stop
                class="absolute white-gradient px-4 py-6 max-w-sm w-full bg-white mt-1 rounded-md"
              >
                <div class="flex w-full justify-between">
                  <div class="w-full ml-8">
                    <p class="text-xs font-medium">By Date</p>
                    <div class="mt-2 flex items-center">
                      <input type="radio" name="" id="" />
                      <p class="text-xs ml-3 leading-7">Today</p>
                    </div>
                    <div class="mt-2 flex items-center">
                      <input type="radio" name="" id="" />
                      <p class="text-xs ml-3 leading-7">This Month</p>
                    </div>
                    <div class="mt-2 flex items-center">
                      <input type="radio" name="" id="" />
                      <p class="text-xs ml-3 leading-7">Last Month</p>
                    </div>
                    <div
                      @click="open = true"
                      class="mt-2 flex relative items-center"
                    >
                      <svg
                        class="w-3 h-3"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.975586"
                          y="2.43408"
                          width="12.7281"
                          height="12.2995"
                          rx="1.65333"
                          stroke="#253B95"
                          stroke-width="1.23999"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.168 1.06738V3.8006"
                          stroke="#253B95"
                          stroke-width="1.23999"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.51079 1.06738V3.8006"
                          stroke="#253B95"
                          stroke-width="1.23999"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M0.975586 6.53404H13.7037"
                          stroke="#253B95"
                          stroke-width="1.23999"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <p class="text-xs ml-3 leading-7 text-primaryBlue">
                        Select Date
                      </p>
                      <date-picker
                        class="hidden absolute"
                        ref="calender"
                        :open.sync="open"
                        format="YYYY-MM-DD"
                        type="date"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="border-l pl-8 w-full">
                    <p class="text-xs font-medium">By Status</p>
                    <div class="mt-2 flex items-center">
                      <input type="radio" name="" id="" />
                      <p class="text-xs ml-3 leading-7">Pending</p>
                    </div>
                    <div class="mt-2 flex items-center">
                      <input type="radio" name="" id="" />
                      <p class="text-xs ml-3 leading-7">Paid</p>
                    </div>
                    <div class="mt-2 flex items-center">
                      <input type="radio" name="" id="" />
                      <p class="text-xs ml-3 leading-7">Failed</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="w-full mt-8">
              <div>
                <div
                  :style="`grid-template-columns:${gridColumns}`"
                  class="grid rounded-tl-md rounded-tr-md border"
                >
                  <div
                    v-for="(header, index) in getTableHeader"
                    :key="index"
                    :class="[
                      index == 0 || index === getTableHeader.length - 1
                        ? 'rounded-tl-md rounded-tr-md'
                        : '',
                      { 'grid-space+': index == 0 },
                    ]"
                    class="pl-8 py-6 bg-accentLight uppercase text-xs font-medium w-full"
                  >
                    {{ header }}
                  </div>
                </div>
              </div>
              <div>
                <div
                  :style="`grid-template-columns:${gridColumns}`"
                  :class="[
                    i == contents.length - 1
                      ? 'rounded-bl-md rounded-br-md'
                      : '',
                  ]"
                  class="grid last:border-b border-l border-r even:bg-veryLight items-center"
                  v-for="(content, i) in contents"
                  :key="i"
                >
                  <div
                    class="w-full pl-8 text-xs text-accentDark font-normal flex-col"
                    v-for="(s, u) in items.length"
                    :key="u"
                    :class="[`table-content${u}`, true ? 'py-6' : 'py-8']"
                    @click="openToBeneficiaryModal = true"
                  >
                    <div
                      :class="{
                        ' flex justify-between items-center':
                          u == items.length - 1,
                      }"
                    >
                      <div class="flex items-center">
                        <div
                          v-if="u == 0 && true"
                          class="w-10 h-10 rounded-full border justify-center mr-5 flex items-center"
                        >
                          {{ initials[i] }}
                        </div>
                        <div :class="status(content[items[u]])">
                          {{ content[items[u]] }}
                        </div>
                      </div>
                      <div v-if="u == items.length - 1">
                        <svg
                          width="3"
                          height="15"
                          class="mr-10"
                          viewBox="0 0 3 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="1.825"
                            cy="7.5999"
                            r="0.825"
                            stroke="#253B95"
                            stroke-width="0.9"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <circle
                            cx="1.825"
                            cy="1.825"
                            r="0.825"
                            stroke="#253B95"
                            stroke-width="0.9"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <circle
                            cx="1.825"
                            cy="13.3748"
                            r="0.825"
                            stroke="#253B95"
                            stroke-width="0.9"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <trac-modal
      @close="openToBeneficiaryModal = false"
      size="max-w-2xl"
      v-if="openToBeneficiaryModal"
    >
      <div class="mt-8 pb-10 px-8">
        <div class="mt-5 mb-2 text-base font-medium">To Beneficiary</div>
        <div class="mt-8 flex justify-between items-center">
          <div>
            <div class="text-xs text-primaryBlue">Available Balance</div>
            <div class="mb-2 text-lg font-medium">NGN 12,00,000</div>
          </div>
          <div class="flex items-center">
            <div>
              <div
                class="p-3 rounded-full bg-accentLight flex items-center justify-center"
              >
                <div class="text-sm font-light">JD</div>
              </div>
            </div>
            <div class="ml-3">
              <div class="text-sm font-medium">John Doe</div>
              <div
                class="flex font-light text-xs text-primaryGray items-center"
              >
                <div class="">20009092929</div>
                <div class="border-l ml-2 pl-2 border-gray-400">
                  Zenith Bank Nigeria
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-12 mr-20">
          <trac-input placeholder="Amount to transfer"></trac-input>
        </div>
        <div class="mt-10 mr-20">
          <trac-input placeholder="Add Description (optional)"></trac-input>
        </div>
        <div class="flex justify-end w-full pr-20 mt-10 items-end">
          <trac-button
            @click.native="
              (openToBeneficiaryModal = false), (summaryModal = true)
            "
          >
            <span class="px-6">Proceed</span>
          </trac-button>
        </div>
      </div>
    </trac-modal>
    <trac-modal
      @close="summaryModal = false"
      size="max-w-2xl"
      v-if="summaryModal"
    >
      <div class="mt-8 pb-10 px-8">
        <div class="mt-5 mb-2 text-base font-medium">Summary</div>
        <div
          class="flex flex-col justify-center items-center"
          v-if="summaryDigit"
        >
          <div class="text-xs font-light mt-3">
            Enter your 4 Digit security PIN
          </div>
          <div class="flex mt-8 w-64 justify-between">
            <input
              autofocus
              class="border border-gray-500 w-10 h-10 text-center"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              v-on:keyup="
                check(numberOne)
                  ? $event.target.nextElementSibling.focus()
                  : false
              "
              v-model.number="numberOne"
            />
            <input
              class="border border-gray-500 w-10 h-10 text-center"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              v-on:keyup="
                check(numberTwo)
                  ? $event.target.nextElementSibling.focus()
                  : false
              "
              v-model.number="numberTwo"
            />
            <input
              class="border border-gray-500 w-10 h-10 text-center"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              v-on:keyup="
                check(numberThree)
                  ? $event.target.nextElementSibling.focus()
                  : false
              "
              v-model.number="numberThree"
            />
            <input
              class="border border-gray-500 w-10 h-10 text-center"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              v-on:keyup="
                check(numberFour)
                  ? $event.target.nextElementSibling.focus()
                  : false
              "
              v-model.number="numberFour"
            />
          </div>
          <div class="mt-12">
            <trac-button
              @click.native="(summaryModal = false), (successModal = true)"
            >
              <div class="px-10">Submit</div>
            </trac-button>
          </div>
          <div class="font-bold text-primaryBlue mt-5 text-xs mb-8 fo">
            Set new PIN
          </div>
        </div>
        <div class="mt-8 table-shadow rounded-md p-6 items-center">
          <div class="flex justify-between items-center">
            <div>
              <div class="text-xs text-primaryBlue">Available Balance</div>
              <div class="mb-2 text-lg font-medium">NGN 12,00,000</div>
            </div>
            <div class="flex items-center">
              <div>
                <div
                  class="p-3 rounded-full bg-accentLight flex items-center justify-center"
                >
                  <div class="text-sm font-light">JD</div>
                </div>
              </div>
              <div class="ml-3">
                <div class="text-sm font-medium">John Doe</div>
                <div
                  class="flex font-light text-xs text-primaryGray items-center"
                >
                  <div class="">20009092929</div>
                  <div class="border-l ml-2 pl-2 border-gray-400">
                    Zenith Bank Nigeria
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 items-center mt-8">
            <div>
              <div class="text-xs text-primaryGray">Amount</div>
              <div class="mb-2 text-lg font-medium">NGN 10,000</div>
            </div>
            <div>
              <div class="text-xs text-primaryGray">Fees</div>
              <div class="mb-2 text-lg font-medium">NGN 1000</div>
            </div>
          </div>
        </div>

        <div class="flex justify-end w-full mt-10 items-end">
          <trac-button v-if="!summaryDigit" @click.native="summaryDigit = true">
            <span class="px-6">Proceed</span>
          </trac-button>
        </div>
      </div>
    </trac-modal>
    <trac-modal
      @close="successModal = false"
      size="max-w-2xl"
      v-if="successModal"
    >
      <div class="flex justify-center items-center flex-col mt-12">
        <svg
          class="w-40 h-40"
          viewBox="0 0 211 191"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="93.997"
            cy="97.2492"
            r="53.3316"
            fill="#11A529"
            fill-opacity="0.12"
            stroke="#B2E7FF"
            stroke-width="1.33329"
          />
          <path
            d="M67.9978 98.5822L78.5172 111.742C79.6261 113.13 81.6645 113.321 83.0118 112.163L121.329 79.2495"
            stroke="#253B95"
            stroke-width="5.33316"
          />
          <path
            d="M37.0193 34.9842C37.9425 38.3495 45.552 45.2178 45.4618 33.4581C45.3716 21.6984 50.1024 24.1263 54.929 26.1249"
            stroke="#0143AA"
            stroke-width="1.00759"
          />
          <path
            d="M96.4005 21.3851C99.4466 24.6887 112.212 28.0456 105.155 14.6472C98.0984 1.24885 104.946 1.22861 111.648 0.660829"
            stroke="#0143AA"
            stroke-width="1.29746"
          />
          <path
            d="M23.6143 119.778C19.2171 115.703 1.88667 112.575 12.6757 129.656C23.4647 146.737 14.3597 147.481 5.5042 148.938"
            stroke="#0143AA"
            stroke-width="1.731"
          />
          <path
            d="M186.448 122.734C180.504 123.514 167.027 134.85 187.061 137.46C207.095 140.07 201.852 147.55 197.316 155.295"
            stroke="#B2E7FF"
            stroke-width="1.731"
          />
          <circle cx="79.3308" cy="167.914" r="4.66652" fill="#253B95" />
          <circle cx="107.997" cy="181.434" r="9.33303" fill="#B2E7FF" />
          <circle cx="35.3323" cy="63.9165" r="4.66652" fill="#06B6CB" />
          <circle cx="175.328" cy="79.9164" r="8.66639" fill="#06B6CB" />
          <path
            d="M143.995 20.2515L146.876 28.0369L154.662 30.9178L146.876 33.7987L143.995 41.5841L141.115 33.7987L133.329 30.9178L141.115 28.0369L143.995 20.2515Z"
            fill="#06B6CB"
          />
          <path
            d="M5.99981 59.25L7.6203 63.6293L11.9996 65.2498L7.6203 66.8703L5.99981 71.2496L4.37931 66.8703L0 65.2498L4.37931 63.6293L5.99981 59.25Z"
            fill="#06B6CB"
          />
          <path
            d="M155.328 141.248L158.389 149.52L166.661 152.581L158.389 155.641L155.328 163.913L152.267 155.641L143.995 152.581L152.267 149.52L155.328 141.248Z"
            fill="#013E9E"
          />
          <path
            d="M39.126 162.015L43.0188 167.034L49.3631 167.346L44.3438 171.239L44.0317 177.583L40.1389 172.564L33.7946 172.252L38.8139 168.359L39.126 162.015Z"
            fill="#013E9E"
          />
        </svg>
        <div class="font-bold text-primaryBlue mt-5 text-base mb-8 fo">
          Successful!
        </div>
        <div class="mt-8 w-full px-12 max-w-xl pb-6 border-b border-gray-300">
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <div>
                <div
                  class="p-3 rounded-full bg-accentLight flex items-center justify-center"
                >
                  <div class="text-sm font-light">JD</div>
                </div>
              </div>
              <div class="ml-3">
                <div class="text-sm font-medium">John Doe</div>
                <div
                  class="flex font-light text-xs text-primaryGray items-center"
                >
                  <div class="">20009092929</div>
                  <div class="border-l ml-2 pl-2 border-gray-400">
                    Zenith Bank Nigeria
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="mb-2 text-lg font-medium">NGN 12,00,000</div>
            </div>
          </div>
        </div>
        <div class="mt-5 text-xs">
          <div>
            <span class="px-3">07 July 2020</span> |
            <span class="px-3">02:07 pm</span> |
            <span class="px-3">Transaction ID - 354673572</span>
          </div>
        </div>
        <div class="mt-10 text-xs text-primaryBlue flex items-center">
          <div>Share</div>
          <svg
            class="w-3 h-3 ml-2"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="11"
              cy="3"
              r="2"
              stroke="#253B95"
              stroke-width="1.23077"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <circle
              cx="3"
              cy="7.6665"
              r="2"
              stroke="#253B95"
              stroke-width="1.23077"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <circle
              cx="11"
              cy="12.333"
              r="2"
              stroke="#253B95"
              stroke-width="1.23077"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.72681 8.67334L9.28014 11.3267"
              stroke="#253B95"
              stroke-width="1.23077"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.27347 4.00684L4.72681 6.66017"
              stroke="#253B95"
              stroke-width="1.23077"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="mt-12 pb-10">
          <trac-button>
            <span class="px-10">DONE</span>
          </trac-button>
        </div>
      </div>
    </trac-modal>
    <trac-modal v-if="addBeneficiaryModal" @close="addBeneficiaryModal = false">
      <div class="px-10">
        <div class="mt-8 mb-2 text-lg font-medium">Add Beneficiaries</div>
        <div class="mt-12">
          <trac-input placeholder="Account Holder's Name"></trac-input>
        </div>
        <div class="mt-6">
          <trac-dropdown-exteneded
            :neededProperty="'name'"
            selector="Pick a Bank"
            :options="[{ name: 'one', name: 'two' }]"
          ></trac-dropdown-exteneded>
        </div>
        <div class="mt-6">
          <trac-input placeholder="Account Number" type="number"></trac-input>
        </div>
        <div class="mt-10 pb-8 flex justify-end items-end">
          <trac-button>
            <div class="px-5">SAVE &amp; PROCEED</div>
          </trac-button>
        </div>
      </div>
    </trac-modal>
  </main>
</template>


<script>
import ClickOutside from 'vue-click-outside'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { DatePicker },
  data() {
    return {
      active: false,
      open: false,
      items: [],
      openToBeneficiaryModal: false,
      summaryModal: false,
      summaryDigit: false,
      numberOne: null,
      numberTwo: null,
      numberThree: null,
      numberFour: null,
      successModal: false,
      addBeneficiaryModal: false,
      contents: [
        // {
        //   name: 'Starbucks',
        //   accountNumber: 'Pos Transaction',
        //   bankName: '28 March 2020',
        //   amountSent: '1233456789098765',
        //   Action: '+2345606',

        // },
        // {
        //   name: 'Starbucks',
        //   accountNumber: 'Pos Transaction',
        //   bankName: '28 March 2020',
        //   amountSent: '1233456789098765',
        //   Action: '+2345606',
        // }
      ]
    }
  },
  methods: {
    close() {
      this.active = false
    },

    popModal(x) {
      this.modal = true
    },
    check(x) {
      if (typeof x == 'number') {
        return true
      }
    },

    repeat(str, num) {
      var holder = [];
      for (var i = 0; i < num; i++) {
        holder.push(str);
      }
      return holder.join(' ');
    },

    openDate() {
      this.$refs.calender.$el.click()
    },

    status(x) {
      if (x === 'Paid Out') {
        return 'text-green-500 cap font-medium'
      } else if (x === 'Pending') {
        return 'text-yellow-500 cap font-medium'
      }
    },

    titleCase(string) {
      let sentence = string.toLowerCase().split(' ');
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(' ');
    },

    getInitials(string) {
      let names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }
  },
  computed: {
    gridColumns() {
      let k = '1fr'
      let b = this.repeat(k, this.items.length - 1)
      return `1.4fr ${b}`
    },

    getTableHeader() {
      let k = [...this.contents]
      let b = Object.keys(k[0]).map(k => k)
      let u = Object.keys(k[0]).map(k => {
        return this.titleCase(k).replace(/_/g, " ")
      })
      this.items = b
      return u
    },

    initials() {
      let k = [...this.contents]

      let b = k.map(u => {
        return this.getInitials(u.name)
      })
      return b
    },

  },
  directives: {
    ClickOutside
  },


}
</script>

<style>
.table-shadow {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}
.input-shadow {
  box-shadow: 0px 0px 21.3571px rgba(22, 120, 176, 0.12);
}

.table-even {
  background: #f9f9f9;
}
.table-content0 {
  font-weight: 600 !important;
}
.cap {
  text-transform: capitalize !important;
}
.grid-space {
  grid-template-columns: 1.5fr !important;
}
</style>